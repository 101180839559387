import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    policyTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    policyTitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    policyTitle: true;
  }
}

const customThemeSettings: any = {
  typography: {
    fontFamily: 'Poppins, Tahoma, Geneva, Verdana, sans-serif',
    policyTitle: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '38.4px',
      letterSpacing: '-1px',
      textAlign: 'left',
      '@media (min-width: 750px)': {
        fontSize: '42px',
        fontWeight: 700
      }
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          policyTitle: 'h3'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', Tahoma, Geneva, Verdana, sans-serif"
        }
      }
    }
  }
};

export const theme = createTheme(customThemeSettings);
