import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useMediaQuery } from '@mui/material';

import Container, { ContainerTypeMap } from '@mui/material/Container';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

const WrapperPolicy = (props: DefaultComponentProps<ContainerTypeMap>): ReactJSXElement => {
  const { children, ...restProps } = props;
  return (
    <Container
      sx={{
        marginTop: useMediaQuery('(max-width: 750px)') ? '12px' : '38px',
        width: useMediaQuery('(max-width: 750px)') ? '100%' : '750px',
        paddingBottom: '100px',
        padding: '0 20px 70px'
      }}
      {...restProps}>
      {children}
    </Container>
  );
};

export default WrapperPolicy;
