import React, { ReactElement, useEffect } from 'react';

import { Backdrop, Box, CircularProgress } from '@mui/material';

const LoadingScreen: React.FC = (): ReactElement => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Backdrop
      open
      style={{
        height: '100vh',
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: 999,
        width: '100%',
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box sx={{ color: '#5A74FC' }}>
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};

export default LoadingScreen;
