import React, { ReactElement, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

import styles from 'components/WrapperLink/WrapperLink.module.scss';

interface WrapperLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
}

const WrapperLink = ({ to, children, className, ...rest }: WrapperLinkProps): ReactElement => {
  const slash =
    process.env.REACT_APP_WEBSITE_HOST_URL?.toString().endsWith('/') ||
    to.toString().startsWith('/')
      ? ''
      : '/';
  const link = `${process.env.REACT_APP_WEBSITE_HOST_URL}${slash}${to}`;

  return (
    <>
      <Link
        style={{
          textDecoration: 'none',
          color: 'unset'
        }}
        to={link}
        className={clsx(styles.wrapper, className)}
        target="_blank"
        {...rest}>
        {children}
      </Link>
    </>
  );
};

export default WrapperLink;
