import { FUNNELS_LINKS } from 'routes';
import { ITestData } from 'types';
import { MixpanelEvent } from 'utils/constants';
import { mixpanelLogEvent } from 'utils/mixpanel/analytics';

export const soulmatesFunnels: ITestData[] = [
  {
    title: 'Love Language',
    description: 'Discover How You Deal with Feelings',
    link: FUNNELS_LINKS.LOVE_LANGUAGE,
    onClick: () => {
      mixpanelLogEvent(MixpanelEvent.IsUserOrganic, {
        funnel_name: 'love_language',
        source: 'main page'
      });
    }
  },
  {
    title: 'Toxic Relationship',
    description: 'See if your distractions are caused by ADHD',
    link: FUNNELS_LINKS.TOXIC_RELATIONSHIP,
    onClick: () => {
      mixpanelLogEvent(MixpanelEvent.IsUserOrganic, {
        funnel_name: 'toxic_relationship',
        source: 'main page'
      });
    }
  }
];
