import React, { ReactElement } from 'react';
import Wrapper from 'components/Wrapper/Wrapper';
import WrapperLink from 'components/WrapperLink/WrapperLink';
import { IntroImages } from 'data/images/intro_page';
import { soulmatesFunnels } from 'pages/Intro/data_funnels';
import clsx from 'clsx';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

import styles from 'pages/Intro/Intro.module.scss';
import ButtonTest from 'components/ButtonTest/ButtonTest';

export const Intro: React.FC = (): ReactElement => {
  return (
    <>
      <Box className={clsx(styles.bgImage, styles.bgImageRed)}>
        <Box component="img" src={IntroImages.ellipseBgRedImg} alt="soulmates app" />
      </Box>
      <Box className={clsx(styles.bgImage, styles.bgImageBlue)}>
        <Box component="img" src={IntroImages.ellipseBgBlueImg} alt="soulmates app" />
      </Box>
      <Wrapper className={styles.container}>
        <Stack className={styles.content} spacing={3}>
          <Stack className={styles.main} spacing={useMediaQuery('(max-width: 750px)') ? 3 : 4}>
            <Stack
              spacing={useMediaQuery('(max-width: 750px)') ? 3 : 4}
              sx={{
                width: '100%',
                alignItems: 'center'
              }}>
              <Box className={styles.mainImage}>
                <Box component="img" src={IntroImages.mainImg} alt="soulmates app" />
              </Box>
              <Box className={styles.mainTitle}>
                <Typography variant="h1">
                  To start using the app, take the test you find relevant
                </Typography>
              </Box>
            </Stack>
            <div className={styles.linkContainer}>
              {soulmatesFunnels.map(({ title, link, onClick }) => (
                <WrapperLink onClick={onClick} to={link} key={title} className={styles.test}>
                  <Stack className={styles.testWrapper}>
                    <p className={clsx('h3', styles.testTitle)}>{title}</p>
                    <ButtonTest className={styles.testBtn}>Take a Quiz</ButtonTest>
                  </Stack>
                </WrapperLink>
              ))}
            </div>
          </Stack>
        </Stack>
      </Wrapper>
    </>
  );
};
