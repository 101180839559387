import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue, RemoteConfig } from 'firebase/remote-config';

import { AB_TESTS } from 'utils/constants';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDvd8Guwtacpdrjjufkjro-KQ5QiPx43t8',
  authDomain: 'soulmates-landing.firebaseapp.com',
  projectId: 'soulmates-landing',
  storageBucket: 'soulmates-landing.appspot.com',
  messagingSenderId: '55205588623',
  appId: '1:55205588623:web:3f4930c53c9e3caa684afa',
  measurementId: 'G-4LHQ2CB1XW'
};

class FirebaseLogger {
  private app: FirebaseApp;
  private analytics: Analytics;
  public remoteConfig: RemoteConfig;

  constructor(firebaseConfig: FirebaseOptions) {
    this.app = initializeApp(firebaseConfig);
    this.remoteConfig = getRemoteConfig(this.app);
    this.analytics = getAnalytics(this.app);
    this.remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  }
}

export const firebase = new FirebaseLogger(firebaseConfig);

const app: FirebaseApp = initializeApp(firebaseConfig);
const remoteConfig: RemoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000; // Remove in production

export const fetchFirebaseConfig = createAsyncThunk('firebase/fetchConfig', async () => {
  await fetchAndActivate(remoteConfig);
  return {
    isUserFromEurope: getValue(remoteConfig, AB_TESTS.IS_USER_FROM_EUROPE).asBoolean()
  };
});

interface FirebaseState {
  loading: boolean;
  error: string | null;

  isUserFromEurope: boolean;
}

const initialState: FirebaseState = {
  loading: true,
  error: null,

  isUserFromEurope: false
};

const firebaseSlice = createSlice({
  name: 'firebase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFirebaseConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchFirebaseConfig.fulfilled,
        (state, action: PayloadAction<Partial<FirebaseState>>) => {
          state.isUserFromEurope = action.payload.isUserFromEurope ?? false;

          state.loading = false;
        }
      )
      .addCase(fetchFirebaseConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch config';
      });
  }
});

export default firebaseSlice.reducer;
