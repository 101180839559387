import { MixpanelEvent } from 'utils/constants';
import mixpanel from 'utils/mixpanel/config';

let disableMixpanel;

if (typeof window !== 'undefined') {
  disableMixpanel = process.env.NODE_ENV === 'development';
}

interface EventParams {
  [key: string]: string | any;
}

export const mixpanelLogEvent = (eventName: MixpanelEvent, eventParams: EventParams): void => {
  if (disableMixpanel) {
    console.log(`Mixpanel: event: ${eventName}, params: ${JSON.stringify(eventParams)}.`);
  } else {
    try {
      mixpanel.track(eventName, eventParams);
    } catch (error) {
      console.error(error);
    }
  }
};
