import React, { ReactElement } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { IntroImages } from 'data/images/header';

import styles from 'components/Header/Header.module.scss';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const Header: React.FC = (): ReactElement => {
  const location = useLocation();
  const breadcrumbPaths = [
    {
      label: 'Privacy Policy',
      path: ROUTES.PRIVACY_POLICY
    },
    {
      label: 'Subscription Terms',
      path: ROUTES.SUBSCRIPTION_TERMS
    },
    {
      label: 'Terms of Service',
      path: ROUTES.TERMS_OF_SERVICE
    }
  ];
  const showBreadcrumbs = breadcrumbPaths.find(
    (item) => item.path === (location.pathname as ROUTES)
  );

  return (
    <>
      <Box component="header" className={styles.header}>
        <Box className={styles.container}>
          {/* <Box display='inline'> */}
          <Box
            component="img"
            className={styles.logo}
            src={IntroImages.logoImg}
            alt="soulmates logo"
          />
          {/* </Box> */}
        </Box>
        {showBreadcrumbs && (
          <Box className={styles.breadcrumbs}>
            <Breadcrumbs items={[{ label: showBreadcrumbs.label, path: showBreadcrumbs.path }]} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Header;
