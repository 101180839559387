import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { ROUTES } from 'routes';

import styles from 'components/Footer/Footer.module.scss';

const Footer: React.FC = (): ReactElement => {
  return (
    <Box className={styles.wrapper}>
      <Stack className={styles.footer} spacing={1}>
        <Stack className={styles.footerLinks}>
          <Link
            to={ROUTES.TERMS_OF_SERVICE}
            relative="path"
            target="_blank"
            className={styles.footerLink}>
            Terms of Service
          </Link>
          <Link
            to={ROUTES.PRIVACY_POLICY}
            relative="path"
            target="_blank"
            className={styles.footerLink}>
            Privacy Policy
          </Link>
          <Link
            to={ROUTES.SUBSCRIPTION_TERMS}
            relative="path"
            target="_blank"
            className={styles.footerLink}>
            Subscription Terms
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
