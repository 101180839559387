import React from 'react';
import clsx from 'clsx';
import { Button, ButtonTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

import styles from 'components/ButtonTest/ButtonTest.module.scss';

const ButtonTest = (props: DefaultComponentProps<ButtonTypeMap>) => {
  const { children, className, ...restProps } = props;
  return (
    <Button className={clsx(styles.button, className)} {...restProps} variant="outlined">
      {children}
    </Button>
  );
};

export default ButtonTest;
