export enum MixpanelEvent {
  IsUserOrganic = 'is_user_organic'
}

export const IS_STAGING =
  window.location.href.includes('qa') ||
  window.location.href.includes('staging') ||
  process.env.NODE_ENV === 'development';
export const ROUTER_BASENAME = IS_STAGING ? '/soulmates-landing' : '/';

export const AB_TESTS = Object.freeze({
  IS_USER_FROM_EUROPE: 'is_user_from_europe'
});
