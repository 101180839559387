import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { ROUTES } from 'routes';

import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  items: { label: string; path: string }[];
}
const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }): ReactElement => {
  return (
    <Stack className={styles.breadcrumbs}>
      <Link to={ROUTES.INTRO}>Home</Link>
      {items.map(({ label, path }, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <Stack sx={{ flexDirection: 'row', gap: '8px' }} key={label}>
            <Box component="span">/</Box>
            {isLastItem ? (
              <Box className={styles.breadcrumbsCurrent}>{label}</Box>
            ) : (
              <Link to={ROUTES[path]}>{label}</Link>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Breadcrumbs;
