const API_URL = 'https://cms.breeze-wellbeing.com/graphql';

export async function fetchAPI(query = '', { variables }: Record<string, any> = {}) {
  const headers = { 'Content-Type': 'application/json' };

  const res = await fetch(API_URL, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      query,
      variables
    })
  });

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error('Failed to fetch API');
  }
  return json.data;
}

const page_by_slug = (slug: string) => `
query GetPageBy {
  pageBy(uri: "${slug}") {
    content
    id
    slug
    title
  }
}`;
export async function fetchWpPageBySlug(slug: string) {
  try {
    const data = await fetchAPI(page_by_slug(slug));
    return data.pageBy;
  } catch (error) {
    console.error(`Error fetching page: ${slug}`, error);
    throw new Error(`Error fetching page: ${slug}`);
  }
}
