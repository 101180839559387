import clsx from 'clsx';
import sanitizeHtml from 'sanitize-html';
import React, { FC, ReactElement } from 'react';

import styles from './index.module.scss';

interface ISanitizedHtmlBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  body?: string;
}
const addLazyLoadingToImages = (html: string | undefined): string => {
  if (!html) return '';
  return html.replace(/<img([^>]*?)(\sloading="[^"]*")?>/gi, '<img$1 loading="lazy">');
};
const SanitizedHtmlBody: FC<ISanitizedHtmlBodyProps> = ({
  body,
  className,
  ...restProps
}): ReactElement => {
  const processedBody = body ? addLazyLoadingToImages(body) : '';

  return body ? (
    <div className={clsx(styles.container, className)} {...restProps}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(processedBody, {
            allowedIframeHostnames: ['www.youtube.com'],
            allowedIframeDomains: ['www.youtube.com'],
            allowedAttributes: {
              a: ['href', 'id', 'target'],
              h1: ['id'],
              h2: ['id'],
              h3: ['id'],
              h4: ['id'],
              h5: ['id'],
              h6: ['id'],
              ol: ['start'],
              div: ['id'],
              p: ['id'],
              img: ['src', 'alt', 'id'],
              iframe: [
                'title',
                'loading',
                'width',
                'height',
                'src',
                'source',
                'frameborder',
                'allow',
                'allowfullscreen'
              ]
            },
            allowedTags: [
              'p',
              'div',
              'br',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'span',
              'img',
              'ul',
              'ol',
              'li',
              'a',
              'em',
              'strong',
              'article',
              'figure',
              'iframe',
              'table',
              'tr',
              'td'
            ]
          })
        }}
      />
    </div>
  ) : (
    <div className="flex-column">No content</div>
  );
};

export default SanitizedHtmlBody;
