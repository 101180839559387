import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import firebaseSlice from './firebaseSlice';
import { AppDispatch, RootState } from './types';

const combineReducer = combineReducers({ firebaseSlice });
export const store = configureStore({
  reducer: combineReducer
});

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
