import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import App from 'App';
import { ROUTER_BASENAME } from './utils/constants';
import { store } from './redux_utils/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <BrowserRouter basename={ROUTER_BASENAME}>
          <App />
        </BrowserRouter>
      </Provider>
    </StyledEngineProvider>
  </ThemeProvider>
);
